exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-nikki-js": () => import("./../../../src/pages/about-nikki.js" /* webpackChunkName: "component---src-pages-about-nikki-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-getting-started-js": () => import("./../../../src/pages/getting-started.js" /* webpackChunkName: "component---src-pages-getting-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-toolbox-js": () => import("./../../../src/pages/toolbox.js" /* webpackChunkName: "component---src-pages-toolbox-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/eventTemplate.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-service-in-person-js": () => import("./../../../src/templates/serviceInPerson.js" /* webpackChunkName: "component---src-templates-service-in-person-js" */),
  "component---src-templates-service-online-js": () => import("./../../../src/templates/serviceOnline.js" /* webpackChunkName: "component---src-templates-service-online-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/serviceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-toolbox-template-js": () => import("./../../../src/templates/toolboxTemplate.js" /* webpackChunkName: "component---src-templates-toolbox-template-js" */)
}

